import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { BsTrashFill } from 'react-icons/bs';
import {
  FaBookMedical,
  FaCalendar,
  FaChartBar,
  FaCheck,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaCircle,
  FaClipboard,
  FaEye,
  FaFileDownload,
  FaFilter,
  FaHeadset,
  FaHospitalAlt,
  FaPencilAlt,
  FaPills,
  FaQuestion,
  FaSyringe,
  FaTable,
  FaUser,
  FaWarehouse,
  FaUserAltSlash,
  FaUserCheck,
  FaUserEdit
} from 'react-icons/fa';
import {
  FaFileArrowUp,
  FaMagnifyingGlass,
  FaPlus,
  FaUsersGear
} from 'react-icons/fa6';
import { IoDocumentText, IoReload } from 'react-icons/io5';
import { LuFileCheck } from 'react-icons/lu';
import {
  MdClose,
  MdCompareArrows,
  MdNewReleases,
  MdPeopleAlt,
  MdOutlineSecurity
} from 'react-icons/md';
import { PiFilesFill } from 'react-icons/pi';
import { RiUserAddFill } from 'react-icons/ri';

export const ICONS = {
  bookMedical: FaBookMedical,
  calendar: FaCalendar,
  chartBar: FaChartBar,
  check: FaCheck,
  chevronUp: FaChevronUp,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  chevronDown: FaChevronDown,
  circle: FaCircle,
  clipboard: FaClipboard,
  close: MdClose,
  documentText: IoDocumentText,
  eye: FaEye,
  fileArrowUp: FaFileArrowUp,
  fileCheck: LuFileCheck,
  fileDownload: FaFileDownload,
  files: PiFilesFill,
  filter: FaFilter,
  headset: FaHeadset,
  hospital: FaHospitalAlt,
  infoCircle: AiOutlineInfoCircle,
  logout: BiLogOut,
  magnifyingGlass: FaMagnifyingGlass,
  outlineSecurity: MdOutlineSecurity,
  pencil: FaPencilAlt,
  peopleAlt: MdPeopleAlt,
  pills: FaPills,
  plus: FaPlus,
  question: FaQuestion,
  releases: MdNewReleases,
  reload: IoReload,
  syringe: FaSyringe,
  table: FaTable,
  trashFill: BsTrashFill,
  user: FaUser,
  userAddFill: RiUserAddFill,
  userAltSlash: FaUserAltSlash,
  userCheck: FaUserCheck,
  userEdit: FaUserEdit,
  usersGear: FaUsersGear,
  warehouse: FaWarehouse,
  compareArrows: MdCompareArrows
};
