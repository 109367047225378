//* External
import Lottie from 'lottie-react';
import { Suspense, lazy } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';

//* App Custom
import AppLoader from 'assets/meditrack_loader.json';
import { ROUTES } from 'constants/index';
import { useLocalStorage } from 'hooks/index';
import { privateRouteOptions } from 'utils/index';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

const Routes = () => {
  const { sidebarData } = useLocalStorage();

  const Error404 = lazy(() => import('pages/Error404/Error404'));
  const LandingPage = lazy(() =>
    import('pages/Public/LandingPage/LandingPage')
  );
  const LoginPage = lazy(() => import('pages/Public/LoginPage/LoginPage'));

  const UserAuthenticated = lazy(() =>
    import('layouts/UserAuthenticated/UserAuthenticated')
  );
  const Home = lazy(() => import('pages/Private/Home/Home'));
  const Dashboard = lazy(() => import('pages/Private/Dashboard/Dashboard'));
  const FileLoad = lazy(() =>
    import('pages/Private/MassiveLoad/FileLoad/FileLoad')
  );
  const FileHistory = lazy(() =>
    import('pages/Private/MassiveLoad/FileHistory/FileHistory')
  );
  const PatientList = lazy(() =>
    import('pages/Private/Patients/PatientList/PatientList')
  );
  const Patient = lazy(() => import('pages/Private/Patients/Patient/Patient'));
  const PatientsNotContacted = lazy(() =>
    import('pages/Private/Patients/PatientsNotContacted/PatientsNotContacted')
  );
  const PatientsWithNovelty = lazy(() =>
    import('pages/Private/Patients/PatientsWithNovelty/PatientsWithNovelty')
  );
  const Programming = lazy(() =>
    import('pages/Private/Programming/Programming')
  );
  const Dispensation = lazy(() =>
    import('pages/Private/Dispensation/Dispensation')
  );
  const TechnicalReception = lazy(() =>
    import('pages/Private/TechnicalReception/TechnicalReception')
  );
  const Application = lazy(() =>
    import('pages/Private/Application/Application')
  );
  const Reports = lazy(() => import('pages/Private/Reports/Reports'));
  const Inventory = lazy(() => import('pages/Private/Inventory/Inventory'));
  const Addressing = lazy(() => import('pages/Private/Addressing/Addressing'));
  const Users = lazy(() => import('pages/Private/Users/Users'));
  const UserManagementForm = lazy(() =>
    import('pages/Private/Users/UserManagementForm/UserManagementForm')
  );
  const GroupsManagement = lazy(() =>
    import('pages/Private/Users/GroupsManagement/GroupsManagement')
  );

  const pageSelected = (name) => {
    const pageDirectory = {
      monitoring: Dashboard,
      old_monitoring: Dashboard,
      file_load: FileLoad,
      file_history: FileHistory,
      patient_list: PatientList,
      patients_not_contacted: PatientsNotContacted,
      patients_with_novelty: PatientsWithNovelty,
      programming: Programming,
      dispensation: Dispensation,
      technical_reception: TechnicalReception,
      application: Application,
      reports: Reports,
      inventory: Inventory,
      addressing: Addressing,
      users: Users
    };
    const Page = pageDirectory[name];
    return <Page />;
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Navigate to={ROUTES.ERROR} />
    },
    {
      path: ROUTES.ERROR,
      element: <Error404 />
    },
    {
      path: ROUTES.ROOT,
      element: (
        <PublicRoute>
          <LandingPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.LOGIN,
      element: (
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.PLATFORM,
      element: <UserAuthenticated sidebarData={sidebarData} />,
      children: [
        {
          path: ROUTES.HOME,
          element: (
            <PrivateRoute module={{ title: 'routes.home' }}>
              <Home />
            </PrivateRoute>
          )
        },
        ...privateRouteOptions(sidebarData).map((item) => ({
          path: `${ROUTES.PLATFORM}/${item.functional_name}`,
          element: (
            <PrivateRoute module={{ title: item.label }}>
              {pageSelected(item.functional_name)}
            </PrivateRoute>
          )
        })),
        {
          path: ROUTES.PATIENT,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.patient',
                breadcrumbs: [
                  {
                    route: ROUTES.PATIENT_LIST,
                    label: 'routes.patient_list'
                  }
                ]
              }}
            >
              <Patient />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.USER_UPDATE,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.update_user',
                breadcrumbs: [{ label: 'routes.users', route: ROUTES.USERS }]
              }}
            >
              <UserManagementForm />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.USER_CREATE,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.create_user',
                breadcrumbs: [{ label: 'routes.users', route: ROUTES.USERS }]
              }}
            >
              <UserManagementForm />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.GROUPS_MANAGEMENT,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.groups',
                breadcrumbs: [{ label: 'routes.users', route: ROUTES.USERS }]
              }}
            >
              <GroupsManagement />
            </PrivateRoute>
          )
        }
      ]
    }
  ]);

  return (
    <Suspense
      fallback={
        <div className="loadingContainer">
          <Lottie
            animationData={AppLoader}
            loop={true}
            style={{ width: 320 }}
          />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
