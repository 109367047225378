//* External
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

//* App Custom
import { Button, CustomIcon, InputHandler, Wrapper } from 'components/common';
import './dynamicFormSection.scss';
import { phoneInputs } from './dynamicFormSectionData';

const DynamicFormSection = ({
  name,
  icon,
  control,
  otherFormOptions,
  defaultInputs
}) => {
  const { append, remove, fields: section } = useFieldArray({ control, name });

  return (
    <div>
      <div className="d-flex align-items-center gap-1 mb-1">
        {icon && <CustomIcon name={icon} />}
      </div>

      <div className="d-flex" key={`default_${name}`}>
        <Col sm="10" md="11">
          {defaultInputs.length > 0 && (
            <Row>
              {defaultInputs.map((input) => (
                <InputHandler
                  key={input.id}
                  {...input}
                  name={`default_${name}.${input.name}`}
                  control={control}
                />
              ))}
            </Row>
          )}
        </Col>
      </div>

      {section.map((inputData, index) => {
        const inputsToDisplay = (inputIndex) =>
          ({
            phones: phoneInputs({ inputIndex, inputData, otherFormOptions })
          }[name] || []);

        return (
          <div className="d-flex" key={`${name}[${index}]`}>
            <Col sm="10" md="11">
              {inputsToDisplay(index).length <= 2 ? (
                <Row>
                  {inputsToDisplay(index).map((input) => (
                    <InputHandler {...input} control={control} />
                  ))}
                </Row>
              ) : (
                <div className="w-100 mb-2">
                  <Wrapper border="left">
                    <Row>
                      {inputsToDisplay(index).map((input) => (
                        <InputHandler {...input} control={control} />
                      ))}
                    </Row>
                  </Wrapper>
                </div>
              )}
            </Col>
            <Col
              sm="2"
              md="1"
              className={`trashContainer trashContainer_${
                inputsToDisplay.length <= 2 ? 'twoItems' : 'manyItems'
              }`}
            >
              <Button
                iconName="trashFill"
                disabled={index === 0}
                border={inputsToDisplay.length <= 2 ? 'none' : 'right'}
                onClick={() => remove(index)}
              />
            </Col>
          </div>
        );
      })}

      {name !== 'user_Management_form' && (
        <div className="mt-2">
          <Button
            variant="secondary"
            text={`btns.add_${name}`}
            iconName="plus"
            onClick={() => append({})}
          />
        </div>
      )}
    </div>
  );
};

DynamicFormSection.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  control: PropTypes.any
};

export default DynamicFormSection;
